import styled from 'styled-components';

const StyledHeaderContainer = styled.header`
    min-height: 50px;
    display: flex;
    align-items: center;

    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    border-bottom: 3px solid ${({ theme }) => theme.palette.secondary.main};
`;

const StyledBrowserWarning = styled.div`
    padding: 10px 0;
    color: ${({ theme }) => theme.palette.common.black};
    background: ${({ theme }) => theme.palette.secondary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    text-align: center;
    line-height: 10px;
`;

const StyledTitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 20%;
    padding-left: 5px;
`;

const StyledAccountContainer = styled.div`
    flex: 1 1 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-right: 10px;
    font-weight: 600;
    font-size: 13px;
`;

export { StyledHeaderContainer, StyledTitleContainer, StyledAccountContainer, StyledBrowserWarning };
