import React, { CSSProperties } from 'react';
import { StyledLinkButton } from '../styles/LinkButton.styled';

interface ILinkButtonProps {
  style?: CSSProperties;
  className?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  text: string;
}

const LinkButton = (props: ILinkButtonProps) => {
  return (
    <StyledLinkButton 
      style={props.style} 
      className={props.className} 
      onClick={props.onClick}
    >
      {props.text}
    </StyledLinkButton>
  );
}

export default LinkButton;