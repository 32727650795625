import { ApolloClient } from "apollo-client";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

export function createGraphqlClient(uri: string, token: string | null): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({
    uri: uri,
    credentials: 'same-origin'
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (process.env.NODE_ENV === 'development') {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  });

  return new ApolloClient({
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true
      }
    },
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    resolvers: {},
    cache: new InMemoryCache()
  });
}