import React, { useMemo, useState } from 'react';
import { StyledMenuContainer } from './SideMenu.styles';
import SideMenuAction from './SideMenuAction';
import { FontIcon } from '@fluentui/react';
import SideMenuLink from './SideMenuLink';
import { useConfig } from 'utils/config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import { EnvironmentEnum } from 'config/enums/Environment.enum';
import { useSecurity } from 'utils/security/hooks/useSecurity';
import { AppRoute, appRoutes } from 'routes/app.routes';
import { ExternalHaulierRoute, externalHaulierRoutes } from 'routes/external-haulier.routes';

const SideMenu = () => {
    const { accessToken } = useSecurity();
    const [isExpanded, setIsExpanded] = useState(false);
    const { settings } = useConfig<IAppConfig>();

    async function copyTokenToClipboard() {
        await navigator.clipboard.writeText(accessToken ?? '');
    }

    const menuItems = useMemo(() => {
        const items = [];

        items.push({
            title: 'External hauliers',
            label: 'External Hauliers',
            icon: <FontIcon iconName='ContactList' />,
            to: externalHaulierRoutes[ExternalHaulierRoute.ViewExternalHauliers]()
        });

        return items;
    }, []);

    return (
        <StyledMenuContainer isExpanded={isExpanded}>
            <SideMenuLink
                icon={<FontIcon iconName='DeliveryTruck' />}
                label='Hauliers'
                to={appRoutes[AppRoute.Hauliers]}
                title='Hauliers'
                items={menuItems}
            />

            <div style={{ marginTop: 'auto', borderTop: '1px solid #484848' }} />

            {(settings.Environment === EnvironmentEnum.Development ||
                settings.Environment === EnvironmentEnum.Test) && (
                <SideMenuAction
                    icon={<FontIcon iconName='Ticket' style={{ color: 'white' }} />}
                    label='Access Token'
                    title='Copy the access token to clipboard'
                    onClick={copyTokenToClipboard}
                />
            )}

            <SideMenuAction
                icon={
                    isExpanded ? (
                        <FontIcon iconName='ChevronLeft' />
                    ) : (
                        // <ChevronsLeft strokeWidth='2px' size='24px' style={{ color: 'white' }} />
                        <FontIcon iconName='ChevronRight' />
                        // <ChevronsRight strokeWidth='2px' size='24px' style={{ color: 'white' }} />
                    )
                }
                label={isExpanded ? 'Collapse' : 'Open'}
                onClick={() => setIsExpanded(!isExpanded)}
            />
        </StyledMenuContainer>
    );
};

SideMenu.displayName = 'SideMenu';

export default SideMenu;
