import React, { Suspense, useMemo } from 'react';
import { SecureRoute } from '@okta/okta-react';
import { Redirect, Switch } from 'react-router-dom';
import { StyledAppContainer } from './App.styles';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastProvider } from 'react-toast-notifications';
import { useConfig } from 'utils/config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import PermissionProvider from 'utils/permissions/components/PermissionProvider';
import { useSecurity } from 'utils/security/hooks/useSecurity';
import { AuthStateEnum } from 'utils/security/constants/AuthStateEnum';
import { Authorising } from 'components/app/Authorising';
import { createGraphqlClient } from 'utils/graphql.utils';
import Body from './Body';
import Header from 'components/app/Header';
import Main from 'components/app/Main';
import SideMenu from './SideMenu';
import { ExternalHaulierRoute, externalHaulierRoutes } from 'routes/external-haulier.routes';
import { ViewExternalHauliers } from 'sections/hauliers/external/pages';

const ExistingHaulier = React.lazy(() => import('sections/hauliers/external/pages/ExternalHaulierDetails/Components/ExistingHaulier'));
const NewHaulier = React.lazy(() => import('sections/hauliers/external/pages/ExternalHaulierDetails/Components/NewHaulier'));

/**
 * AppContainer component
 */
const AppContainer = () => {
    const { settings } = useConfig<IAppConfig>();
    const { authState, accessToken, user } = useSecurity();


    const graphQlClient = useMemo(() => createGraphqlClient(settings.GraphQlApiUrl, accessToken), [
        settings,
        accessToken
    ]);

    return (
        <>
            {authState !== AuthStateEnum.Successful && <Authorising authState={authState} />}
            {authState === AuthStateEnum.Successful && (
                <ApolloProvider client={graphQlClient}>
                    <ToastProvider autoDismiss={true} autoDismissTimeout={10000} placement='top-right'>
                        <PermissionProvider user={user}>
                                <StyledAppContainer>
                                    <Header />
                                    <Main>
                                        <SideMenu />
                                        <Body>
                                            <Suspense fallback={<div>&nbsp;</div>}>
                                                <Switch>
                                                    <SecureRoute
                                                        exact
                                                        path={externalHaulierRoutes[
                                                            ExternalHaulierRoute.ViewExternalHauliers
                                                            ]()}
                                                        component={ViewExternalHauliers}
                                                    />
                                                    <SecureRoute
                                                        path={externalHaulierRoutes[
                                                            ExternalHaulierRoute.NewExternalHaulier
                                                            ]()}
                                                        component={NewHaulier}
                                                    />
                                                    <SecureRoute
                                                        path={externalHaulierRoutes[
                                                            ExternalHaulierRoute.ViewExternalHaulier
                                                            ]()}
                                                        component={ExistingHaulier}
                                                    />
                                                    <Redirect
                                                        to={externalHaulierRoutes[
                                                            ExternalHaulierRoute.ViewExternalHauliers
                                                            ]()}
                                                    />
                                                </Switch>
                                            </Suspense>
                                        </Body>
                                    </Main>
                                </StyledAppContainer>
                        </PermissionProvider>
                    </ToastProvider>
                </ApolloProvider>
            )}
        </>
    );
};

export default AppContainer;
