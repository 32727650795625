import styled from 'styled-components';

export const StyledPageHeader = styled.h1`
  ${({theme}) => `
    margin: 25px;
    color: ${theme.palette.primary.main};
  `}
`;

export const StyledHaulierContainer = styled.div`
    padding-bottom: 25px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const StyledNoResults = styled.h2`
  text-align: center;
  margin: 50px 0;
`;