import React, { useEffect, useRef, useState } from 'react';
import { StyledHaulierContainer, StyledPageHeader } from './ViewExternalHauliers.styles';
import { CommandBar, CommandBarContainer, CommandBarLink } from '../../../../../../components/general/CommandBar';
import CommandBarDivider from '../../../../../../components/general/CommandBar/components/CommandBarDivider';
import Select from 'components/general/Select';
import { IDropdownOption, ISearchBoxStyles, SearchBox } from '@fluentui/react';
import FilterBar from '../../../../../../components/general/FilterBar';
import { HaulierStatusEnum } from '../../../../enums/HaulierStatus.enum';
import { Haulier } from '../../../types/ExternalHauliers.types';
import ExternalHaulierListView from './ExternalHaulierListView';
import GraphQlErrorBoundary from '../../../../../../components/shared/ErrorBoundary';
import GraphQlLoading from 'components/shared/Loading';
import { isNullOrEmpty } from '../../../../../../utils/validation.utils';
import { ExternalHaulierRoute, externalHaulierRoutes } from '../../../../../../routes/external-haulier.routes';
import { ApolloError } from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';
import ApolloErrorToastMessage from '../../../../../../utils/errors/components/ApolloErrorToastMessage';
import { useToasts } from 'react-toast-notifications';
import { GetPaginatedUserByAccountType, SearchUserByNameIdAttributeValue } from '../queries/viewHaulier.queries';
import { HaulierMapper } from '../../../../../../utils/mappers/HaulierMapper';
import { PaginatedUser, User } from '../../../../../../types/IUser';
import { useDebounce } from '../../../../../../hooks/useDebounce';

const styles: Partial<ISearchBoxStyles> = {
    root: {
        backgroundColor: 'transparent',
        border: 'none',
        flexGrow: '1',
        margin: 'none',
        padding: 'none',
        selectors: {
            '::after': {
                border: 'none'
            }
        }
    }
};

export const ViewExternalHauliers = () => {

    const [showFilterBar, setShowFilterBar] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<ApolloError>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [defaultPredefinedFilter, setDefaultPredefinedFilter] = useState<string>('1');
    const [currentStatus, setCurrentStatus] = useState<HaulierStatusEnum>(HaulierStatusEnum.Active);
    const [data, setData] = useState<Haulier[]>([]);
    const [paginatedData, setPaginatedData] = useState<Haulier[]>([]);
    const apolloClient = useApolloClient();
    const { addToast } = useToasts();
    const userScrollDiv = useRef<HTMLDivElement>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isMaxUsers, setMaxUsers] = useState<boolean>(false);

    const PAGE_SIZE: number = 20;

    const predefinedFilterOptions = [
        {
            key: '1',
            text: 'Active'
        },
        {
            key: '2',
            text: 'Deactive'
        }
    ];

    useEffect(() => {
        setLoading(true);
        setMaxUsers(false);
        loadNextUsersAsync(false)
            .finally(() => {
                setLoadingError(undefined);
                setLoading(false);
            });

    }, [currentStatus]);

    const [filterStr, setFilterStr] = useDebounce<string>((newValue: string) => {
            if (!isNullOrEmpty(newValue)) {
                setLoading(true);
                apolloClient.query<{ searchUserByNameIdAttribute: User[] }>({
                    query: SearchUserByNameIdAttributeValue,
                    variables: {
                        searchName: newValue.trim(),
                        accountTypeId: 2,
                        attributeTypeId: 2,
                        isActive: currentStatus === 'ACTIVE'
                    },
                    fetchPolicy: 'network-only'
                }).then((result) => {
                    if (!result?.data?.searchUserByNameIdAttribute) {
                        return [];
                    }
                    const mapper = new HaulierMapper();
                    const hauliers = mapper?.mapList(result.data.searchUserByNameIdAttribute);
                    setData(hauliers);

                }).catch((err) => {
                    addToast(
                        <ApolloErrorToastMessage
                            error={err}
                            baseMessage='There was an issue retrieving Users'
                        />,
                        { appearance: 'error' }
                    );
                }).finally(() => {
                    setLoadingError(undefined);
                    setLoading(false);
                });
            } else {
                setData([...paginatedData]);
            }
        },
        200,
        '');

    const handlePredefinedFilterChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        option?: IDropdownOption
    ) => {
        if (option !== undefined) {
            setCurrentStatus(option?.text.toUpperCase() as HaulierStatusEnum);
            setDefaultPredefinedFilter(option?.key.toString());
        }
    };

    const loadNextUsersAsync = async (nextUsers: boolean) => {
        if (!isMaxUsers || !nextUsers) {
            try {
                const result = await apolloClient.query<{ paginatedUserByAccountType: PaginatedUser[] }>({
                    query: GetPaginatedUserByAccountType,
                    variables: {
                        'accountType': 2,
                        'isActive': currentStatus === 'ACTIVE',
                        'pageNumber': nextUsers ? pageNumber + 1 : pageNumber,
                        'pageSize': PAGE_SIZE
                    },
                    fetchPolicy: 'network-only'
                });

                if (result.data?.paginatedUserByAccountType) {
                    if ((pageNumber + 1) * PAGE_SIZE >= result.data?.paginatedUserByAccountType[0]?.totalRecords) {
                        setMaxUsers(true);
                    }
                    const mapper = new HaulierMapper();
                    const hauliers = mapper.mapList(result.data.paginatedUserByAccountType);
                    let newData = [];
                    newData.push(...hauliers);
                    if (nextUsers) {
                        newData.unshift(...data);
                    }
                    setData(newData);
                    setPaginatedData(newData);
                    setPageNumber(nextUsers ? pageNumber + 1 : pageNumber);
                }
            } catch (err) {
                addToast(<ApolloErrorToastMessage error={err} />, { appearance: 'error' });
            }
        }
    };

    const detectScrollBottom = async () => {
        if (!isMaxUsers) {
            if (userScrollDiv.current) {
                if (
                    userScrollDiv.current.scrollHeight - userScrollDiv.current.scrollTop ===
                    userScrollDiv.current.clientHeight
                ) {
                    setLoading(true);
                    await loadNextUsersAsync(true)
                        .finally(() => {
                            setLoading(false);
                        });
                }
            }
        }
    };

    return (
        <StyledHaulierContainer>
            <StyledPageHeader>View External Hauliers</StyledPageHeader>

            <CommandBarContainer>
                <Select
                    defaultSelectedKey={defaultPredefinedFilter}
                    onChange={handlePredefinedFilterChange}
                    options={predefinedFilterOptions}
                    style={{ margin: '0 25px', fontWeight: 600 }}
                    transparent
                    calloutWidth='auto'
                />
                <CommandBarDivider />
                <CommandBar
                    items={[
                        {
                            key: 'addHaulier',
                            text: 'Add',
                            cacheKey: 'addHaulier',
                            data: {
                                link: externalHaulierRoutes[ExternalHaulierRoute.NewExternalHaulier]()
                            },
                            iconProps: { iconName: 'Add' },
                            commandBarButtonAs: CommandBarLink
                        }
                    ]}
                    farItems={[
                        {
                            key: 'filter',
                            text: 'Filter',
                            cacheKey: 'filter',
                            iconProps: { iconName: 'Filter' },
                            iconOnly: true,
                            onClick: () => setShowFilterBar(!showFilterBar)
                        }
                    ]}
                />
            </CommandBarContainer>
            {showFilterBar && (
                <FilterBar>
                    <SearchBox
                        iconProps={{ iconName: 'Filter' }}
                        onChange={(e, v) => setFilterStr(v || '')}
                        placeholder='Filter by Haulier Name'
                        styles={styles}
                        underlined
                        value={filterStr}
                    />
                </FilterBar>
            )}
            <GraphQlErrorBoundary error={loadingError}>
                <GraphQlLoading isLoading={isLoading} message='Loading Users'>
                    <div
                        style={{ flex: '1 1 auto', overflowY: 'scroll', overflowX: 'hidden' }}
                        onScroll={detectScrollBottom}
                        ref={userScrollDiv}>
                        <ExternalHaulierListView data={data} isLoading={isLoading} />
                    </div>
                </GraphQlLoading>
            </GraphQlErrorBoundary>

        </StyledHaulierContainer>
    );
};

