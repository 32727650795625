import styled from 'styled-components';
import { CommandBar } from '@fluentui/react';

const StyledCommandBarContainer = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
`;

const StyledCommandBar = styled(CommandBar)`
    flex: 1 0 auto;
`;

export { StyledCommandBarContainer, StyledCommandBar };
