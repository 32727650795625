import React from 'react';
import { StyledBodyContainer, StyledBody } from './Body.styles';

interface BodyProps {
    children: React.ReactElement;
}

const Body = (props: BodyProps) => {
    return (
        <StyledBodyContainer>
            <StyledBody>{props.children}</StyledBody>
        </StyledBodyContainer>
    );
};

export default Body;
