import styled from 'styled-components';

const StyledLinkButton = styled.div`
  ${({theme}) => `
    font-size: 13px;
    color: ${theme.palette.primary.main};
    cursor: pointer;

    :hover {
      color: ${theme.palette.primary.light};
      text-decoration: underline;
    }

    :disabled {
      color: ${theme.palette.gray[600]};
      text-decoration: none;
      cursor: default;
    }
  `}
`;

export { StyledLinkButton }