import { HaulierStatusEnum } from '../../sections/hauliers/enums/HaulierStatus.enum';
import { Haulier } from '../../sections/hauliers/external/types/ExternalHauliers.types';
import { User, UserAttributes } from '../../types/IUser';
import { DataTypes } from '../../types/DataTypes';

export class HaulierMapper {

    private haulier: User | undefined;

    public map(haulier: User): Haulier{
        this.haulier = haulier;
        return  {
            userId: this.haulier.userId,
            createdDate:  new Date(this.haulier.createdDate),
            userName: this.haulier.userName,
            accountTypeId: this.haulier.accountType.accountTypeId,
            referenceId: this.haulier.referenceId,
            defaultDepot: this.getAttribute("DefaultDepot"),
            haulierName: this.getAttribute("HaulierName"),
            resourceId: this.getAttribute("ResourceId"),
            haulierEmail: this.getAttribute("HaulierEmail"),
            mobileNumber: this.getAttribute("MobileNumber"),
            status: haulier.isActive ? HaulierStatusEnum.Active : HaulierStatusEnum.Deactive
        }
    }

    public mapList(haulierList: User[]): Haulier[]{
        return haulierList.map(x => this.map(x));
    }

    public mapUpdateAttribute(haulier: Haulier): UserAttributes[] {
        return [
            {dataType: DataTypes.STRING, propertyName: "HaulierName", value: haulier.haulierName, propertyNameId: 2},
            {dataType: DataTypes.STRING, propertyName: "HaulierEmail", value: haulier.haulierEmail, propertyNameId: 5},
            {dataType: DataTypes.STRING, propertyName: "MobileNumber", value: haulier.mobileNumber, propertyNameId: 3},
            {dataType: DataTypes.NUMBER, propertyName: "DefaultDepot", value: haulier.defaultDepot.toString(), propertyNameId: 4}
        ]
    }

    private getAttribute(attName: string) : any{
        if(!this.haulier){
            return
        }

        const att = this.haulier.attributes.find(x => x.propertyName === attName);

        if(!att){
            return undefined;
        }

        return this.parseVal(att.dataType, att.value);
    }

    private parseVal(datatype: DataTypes, value: any){
        switch (datatype){
            case DataTypes.STRING:
            case DataTypes.GUID:
                return value;
            case DataTypes.NUMBER:
                if(value.indexOf(".") === -1){
                    return parseInt(value);
                }else{
                    return parseFloat(value);
                }
            case DataTypes.DATE:
                return new Date(value);
            case DataTypes.BOOLEAN:
               if(value === "True"){
                   return HaulierStatusEnum.Active
               }else{
                   return HaulierStatusEnum.Deactive
               }
        }
    }

}