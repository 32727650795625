import { gql } from 'graphql.macro';

export const GetUserById = gql`
    query getUSerById($userId: Int!) {
        userById(userId: $userId) {
            userId
            createdDate
            userName
            isActive
            referenceId
            accountType {
                accountTypeDescription
                accountTypeId
                accountTypeName
            }
            attributes {
                propertyNameId
                propertyName
                value
                dataType
            }
        }
    }
`;

export const SearchUserByNameIdAttributeValue = gql`
    query searchUserByNameIdAttributeValue($searchName: String, $accountTypeId: Int!, $isActive: Boolean!, $attributeTypeId: Int!) {
        searchUserByNameIdAttribute(searchString: $searchName, accountTypeId: $accountTypeId, isActive: $isActive, attributeTypeId: $attributeTypeId) {
            userId
            createdDate
            userName
            referenceId
            accountType {
                accountTypeDescription
                accountTypeId
                accountTypeName
            }
            attributes {
                propertyNameId
                propertyName
                value
                dataType
            }
        }
    }
`;

export const GetPaginatedUserByAccountType = gql`
    query  GetPaginatedUserByAccountType($accountType: Byte!, $isActive: Boolean!, $pageNumber: Int!, $pageSize: Int!){
        paginatedUserByAccountType(accountTypeId: $accountType, isActive: $isActive, pageNumber: $pageNumber, pageSize:  $pageSize) {
            userId
            createdDate
            userName
            referenceId
            isActive
            pageNumber
            pageSize
            totalRecords
            accountType {
                accountTypeDescription
                accountTypeId
                accountTypeName
            }
            attributes {
                propertyNameId
                propertyName
                value
                dataType
            }
        }
    }
`;

export const GetUserComments = gql`
    query UserComments($referenceId : Uuid!){
        userComments(referenceId: $referenceId, deletedStatus: ACTIVE){
            commentId
            referenceId
            comment
            deleted
            createdDate
            createdBy
            createdByUserId
        }
    }
`;