import React, { FC, useCallback, useEffect, useState } from 'react';
import { SecurityContext } from '../context/security-context';
import { useOktaAuth } from '@okta/okta-react';
import { withOkta } from 'utils/security/hocs/withOkta';
import { User } from 'utils/security/types/User';
import { AuthStateEnum } from 'utils/security/constants/AuthStateEnum';
import { AuthRoute, routes as authRoutes } from 'routes/auth.routes';
import { useApolloClient } from '@apollo/react-hooks';
import { GetPaginatedUserByAccountType } from '../../../sections/hauliers/external/pages/ViewExternalHauliers/queries/viewHaulier.queries';
import { gql } from 'graphql.macro';

/**
 * Props interface for {@link SecurityProvider}
 */
interface ISecurityProviderProps {
}

/**
 * SecurityProvider component
 * @param props {@link ISecurityProviderProps}
 */
const SecurityProvider: FC<ISecurityProviderProps> = ({ children }) => {
    const { authState: oktaAuthState, oktaAuth } = useOktaAuth();

    const [authState, setAuthState] = useState<AuthStateEnum>(AuthStateEnum.Pending);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    //const apolloClient = useApolloClient();

    const signOut = useCallback(async () => {
        const logoutUrl = window.location.origin + authRoutes[AuthRoute.PostLogout]();
        await oktaAuth.signOut({ postLogoutRedirectUri: logoutUrl });
    }, [oktaAuth]);

    /** Load user once authenticated */
    useEffect(() => {
        if (authState === AuthStateEnum.Authenticated) {
            oktaAuth.getUser().then(user => {
                setAccessToken(oktaAuthState.accessToken?.value ?? '');
                setUser(user);
                setAuthState(AuthStateEnum.Successful);
            });
        }
    }, [authState, oktaAuth, oktaAuthState]);

    /** Set auth state when okta has finished authenticating */
    useEffect(() => {
        if (oktaAuthState.isAuthenticated && !oktaAuthState.isPending) {
            setAuthState(AuthStateEnum.Authenticated);
        }

        if (!oktaAuthState.isAuthenticated && !oktaAuthState.isPending) {
            setAuthState(AuthStateEnum.Failed);
        }
    }, [oktaAuthState]);

    const updateUser = (userId: number) => {
        if(user)
            setUser({...user, userId: userId})
    }

    return (
        <SecurityContext.Provider
            value={{
                accessToken: accessToken,
                authState: authState,
                user: user,
                updateUserID: updateUser,
                signOut: signOut
            }}
        >
            {children}
        </SecurityContext.Provider>
    );
};

export default withOkta(SecurityProvider);
