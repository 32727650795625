import React from 'react';
import {
    StyledAccountContainer,
    StyledBrowserWarning,
    StyledHeaderContainer,
    StyledTitleContainer
} from './Header.styles';
import { MenuBar, MenuBarSelect } from 'components/general/MenuBar';
import { ReactComponent as Logo } from 'assets/img/sunbeltLogoText.svg';
import { isInternetExplorer } from 'utils/browser.utils';
import { Link } from 'react-router-dom';
import { useSecurity } from 'utils/security/hooks/useSecurity';
import { Persona, PersonaSize } from '@fluentui/react';
import { theme } from 'themes/aplant.theme';
import { setPostLogout } from 'utils/security/utils/post-logout.utils';

const Header = () => {
    const { user, signOut } = useSecurity();

    const handleSignOut = async () => {
        setPostLogout();
        await signOut();
    };

    return (
        <React.Fragment>
            {isInternetExplorer() && (
                <StyledBrowserWarning>
                    For a better experience we do not recommend using Internet Explorer for this application.
                </StyledBrowserWarning>
            )}
            <StyledHeaderContainer>
                <StyledTitleContainer>
                    <Link to='/'>
                        <Logo
                            style={{ height: '40px', width: '132px' }}
                            title='Sunbelt Rentals UK - Logistics Management'
                        />
                    </Link>
                </StyledTitleContainer>
                <StyledAccountContainer>
                    <MenuBar>
                        <Persona
                            text={user?.name}
                            size={PersonaSize.size32}
                            hidePersonaDetails
                            initialsColor={theme.palette.secondary.main}
                            title={user?.name}
                        />
                        <MenuBarSelect invert>
                            <span onClick={handleSignOut}>Logout</span>
                        </MenuBarSelect>
                    </MenuBar>
                </StyledAccountContainer>
            </StyledHeaderContainer>
        </React.Fragment>
    );
};

export default Header;
