import { AppRoute, appRoutes } from 'routes/app.routes';
import { buildRoute } from 'utils/route.utils';

const mainRoute = appRoutes[AppRoute.Hauliers];

export enum ExternalHaulierRoute {
    ViewExternalHauliers = 'viewExternalHauliers',
    ViewExternalHaulier = 'viewExternalHaulier',
    NewExternalHaulier = 'newExternalHaulier'
}

export const externalHaulierRoutes: { [key in ExternalHaulierRoute]: (id?: string) => string } = {
    [ExternalHaulierRoute.ViewExternalHauliers]: () => buildRoute(mainRoute, ['/external']),
    [ExternalHaulierRoute.NewExternalHaulier]: () => buildRoute(mainRoute, ['/external/new']),
    [ExternalHaulierRoute.ViewExternalHaulier]: id => buildRoute(mainRoute, [`/external/${id ?? ':id'}`])
};
