import React, { FC } from 'react';
import {
    StyledText,
    StyledAuthorisingContainer,
    StyledLogo,
    StyledLogoContainer,
    StyledTextContainer
} from './Authorising.styles';
import { AuthStateEnum } from 'utils/security/constants/AuthStateEnum';

/**
 * Props for the Authorising component
 */
interface IAuthorisingProps {
    authState: AuthStateEnum;
}

/**
 * Authorising component
 */
export const Authorising: FC<IAuthorisingProps> = ({ authState }) => {
    return (
        <StyledAuthorisingContainer>
            <StyledLogoContainer>
                <StyledLogo />
            </StyledLogoContainer>
            <StyledText>
                <StyledTextContainer>
                    {authState === AuthStateEnum.Pending && 'Authorising with Okta...'}
                    {authState === AuthStateEnum.Authenticated && 'Loading user details...'}
                    {authState === AuthStateEnum.Failed && 'Authorisation failed, please refresh and try again.'}
                </StyledTextContainer>
            </StyledText>
        </StyledAuthorisingContainer>
    );
};
