/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ICommandBarItemProps, CommandBarButton } from '@fluentui/react';
import { Link } from 'react-router-dom';

const CommandBarLink: React.FunctionComponent<ICommandBarItemProps> = (props: ICommandBarItemProps) => {
    const WrappedButton = () => <CommandBarButton {...(props as any)} />;

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        if (props.disabled) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <Link to={props.data.link!} onClick={handleClick} style={{ outline: 'none' }}>
            <WrappedButton />
        </Link>
    );
};

export default CommandBarLink;
