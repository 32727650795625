import React, { memo } from 'react';
import { StyledFilterBar } from '../styled/FilterBar.styled';

interface IFilterBarProps {
  children: any;
}

const FilterBar = memo(({ children }: IFilterBarProps) => {
  return (
    <StyledFilterBar>
        {children}
    </StyledFilterBar>
  );
});

FilterBar.displayName = 'memo(FilterBar)';

export default FilterBar;

