import React, { useRef, useState, useEffect, FC } from 'react';
import { Haulier } from '../../../types/ExternalHauliers.types';
import { isNullOrUndefined } from '../../../../../../utils/validation.utils';
import { IVirtualTableColumn } from '../../../../../../components/general/Table/components/VirtualTable';
import { VirtualTable } from '../../../../../../components/general/Table';
import { useHistory } from 'react-router-dom';
import { StyledNoResults } from './ViewExternalHauliers.styles';
import { LinkButton } from '../../../../../../components/general/LinkButton';
import { ExternalHaulierRoute, externalHaulierRoutes } from '../../../../../../routes/external-haulier.routes';
import Loading from '../../../../../../components/shared/Loading';

interface HaulierTableProps {
    data: Haulier[];
    isLoading: boolean;
}

function _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean,
    valueResolver?: (item: T) => any
): T[] {
    const key = columnKey as keyof T;
    return [...items].sort((a: T, b: T) => {
        const valueA = !isNullOrUndefined(valueResolver) ? valueResolver(a) : a[key];
        const valueB = !isNullOrUndefined(valueResolver) ? valueResolver(b) : b[key];

        let sortValueA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
        let sortValueB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;

        return (isSortedDescending ? sortValueA < sortValueB : sortValueA > sortValueB) ? 1 : -1;
    });
}

const ExternalHaulierListView: FC<HaulierTableProps> = ({data, isLoading}) => {

    const history = useHistory();
    const displayDataRef = useRef<Haulier[]>([]);
    const [displayData, setDisplayData] = useState<Haulier[]>([]);

    const [displayColumns, setDisplayColumns] = useState<IVirtualTableColumn<Haulier>[]>([

        {
            key: 'column2',
            name: 'Haulier Name.',
            fieldName: 'haulierName',
            minWidth: 350,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: handleColumnClick,
            data: 'string',
            isPadded: true,
            onRender: (item: Haulier) => <span>{item.haulierName}</span>
        },
        {
            key: 'column3',
            name: 'Resource ID.',
            fieldName: 'resourceId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: handleColumnClick,
            data: 'string',
            isPadded: true,
            onRender: (item: Haulier) => <span>{item.resourceId}</span>
        },
        {
            key: 'column4',
            name: 'Default Depot.',
            fieldName: 'defaultDepot',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: handleColumnClick,
            data: 'string',
            isPadded: true,
            onRender: (item: Haulier) => <span>{item.defaultDepot}</span>
        },
        {
            key: 'column5',
            name: 'Created Date.',
            fieldName: 'createdDate',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: handleColumnClick,
            data: 'string',
            isPadded: true,
            onRender: (item: Haulier) => <span>{item.createdDate.toLocaleDateString()}</span>
        },
        {
            key: 'column6',
            name: '',
            minWidth: 50,
            maxWidth: 50,
            data: 'date',
            isPadded: true,
            onRender: (item: Haulier) => (
                <LinkButton
                    text='View'
                    onClick={e => {
                        e.preventDefault();
                        history.push(
                            externalHaulierRoutes[ExternalHaulierRoute.ViewExternalHaulier](item.userId.toString())
                        );
                    }}
                />
            )
        }
    ]);

    useEffect(() => {
        const newItems = _copyAndSort(data, 'userName', false);
        setDisplayData(newItems);
        displayDataRef.current = newItems;
    }, [data]);

    function handleColumnClick(ev: React.MouseEvent<HTMLElement>, column: IVirtualTableColumn<Haulier>): void {
        const newColumns: IVirtualTableColumn<Haulier>[] = [...displayColumns];
        const currColumn: IVirtualTableColumn<Haulier> = newColumns.filter(
            currCol => column.key === currCol.key
        )[0];

        newColumns.forEach((newCol: IVirtualTableColumn<Haulier>) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = _copyAndSort(
            displayDataRef.current,
            currColumn.fieldName!,
            currColumn.isSortedDescending,
            currColumn.sortValueResolver
        );

        setDisplayData(newItems);
        setDisplayColumns(newColumns);
    }

    const handleRowClick = (item: any) => {
        history.push(externalHaulierRoutes[ExternalHaulierRoute.ViewExternalHaulier](item.userId.toString()));
    };

    return (
        <Loading isLoading={isLoading}>
            {(isNullOrUndefined(data) || data.length === 0) && (
                <StyledNoResults>No external hauliers found for the currently selected filters</StyledNoResults>
            )}

            {!isNullOrUndefined(data) && data.length > 0 && (
                <VirtualTable<Haulier>
                    columns={displayColumns}
                    items={displayData}
                    onRowClick={handleRowClick}
                    keyFieldName='userId'
                />
            )}
        </Loading>
    );
}

export default ExternalHaulierListView;