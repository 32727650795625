import React from 'react';
import { StyledCommandBarContainer } from '../styled/CommandBarContainer.styled';

interface CommandBarContainerProps {
  children: any;
}

const CommandBarContainer = ({children}: CommandBarContainerProps) => {
  return (
    <StyledCommandBarContainer>
      {children}
    </StyledCommandBarContainer>
  );
};

export default CommandBarContainer;