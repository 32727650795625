import styled from 'styled-components';

const StyledCommandBarDivider = styled.div`
  ${({theme}) => `
    width: 1px;
    background: ${theme.palette.gray[300]};
    height: 60%;
  `}
`;

export { StyledCommandBarDivider };